/* eslint-disable */

import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AcceptCheckboxQuestion from '../questions/AcceptCheckboxQuestion';
import moment from 'moment';
import {
  Buttons,
  ShadowedContainer,
  ProductSlider,
  Table,
  InputSelect,
  SmallDangerButton,
  SmallPrimaryButton,
  Errors,
  PageButtons,
  Form,
} from '@simply-fin-services/astronomix3';
import InputQuestion from '../questions/InputQuestion';
import RadioSelectQuestion from '../questions/RadioSelectQuestion';
import * as R from 'ramda';
import { toCurrencyNoDecimal, toCurrency } from '../common/numberFormats';
import DateQuestion from '../questions/DateQuestion';
import { debounce } from '../../actions/extendedFamilyInsuredsActions';
import QuestionContainer from '../questions/QuestionContainer';

const Input = styled.input`
  display: none;
  line-height: 19px;
`;
const LI = styled.li`
  list-style-position: outside;
  margin-left: 16px;
`;
const Tooltip = styled.div`
  line-height: 19px;
`;
const TH = styled.th`
  width: 25%;
`;

const TD = styled.td`
  width: 25% !important;
  justify-content: flex-start !important;
  vertical-align: baseline;
`;

const inputQuestionField = (
  fieldName,
  placeholder,
  answerQuestion,
  questionId,
  answer
) => (
  <InputQuestion
    title={fieldName}
    placeholder={placeholder}
    questionId={questionId}
    answerQuestion={answerQuestion}
    {...answer}
  />
);

// NB!!! These have been moved into their own configs, see dogmatix/src/domain/productCatalogue/config/affiliations.json
// Ideally the next time these need to be changed we should make use of the new configs and port these over there, as
// they are also being used in polygaul. Nelson Pascoal

const affiliationKeys = {
  Parent: 0,
  Sibling: 1,
  // Other
  'Child over 21': 2,
  'Additional spouse': 2,
  Aunt: 2,
  Uncle: 2,
  Grandchild: 2,
  Grandparent: 2,
  'Great Grandchild': 2,
  'Great Grandparent': 2,
  // Extended Other
  'First Cousin': 3,
  Nephew: 3,
  Niece: 3,
};

const affiliationsByProduct = {
  'sim-extended-single-life-funeral': {
    // Immediate Family:
    Spouse: 4,
    'Child Under 21': 5,
    Parent: 0,
    Sibling: 1,
    // Other
    'Child over 21': 2,
    'Additional spouse': 2,
    Aunt: 2,
    Uncle: 2,
    Grandchild: 2,
    Grandparent: 2,
    'Great Grandchild': 2,
    'Great Grandparent': 2,
    // Extended Other
    'First Cousin': 3,
    Nephew: 3,
    Niece: 3,
  },
  'sim-extended-single-life-funeral-v1': {
    // Immediate Family:
    Spouse: 4,
    'Child Under 21': 5,
    Parent: 0,
    Sibling: 1,
    // Other
    'Child over 21': 2,
    'Additional spouse': 2,
    Aunt: 2,
    Uncle: 2,
    Grandchild: 2,
    Grandparent: 2,
    'Great Grandchild': 2,
    'Great Grandparent': 2,
    // Extended Other
    'First Cousin': 3,
    Nephew: 3,
    Niece: 3,
  },
  'sim-extended-single-life-funeral-flexi': {
    // Immediate Family:
    Spouse: 4,
    'Child Under 21': 5,
    Parent: 0,
    Sibling: 1,
    // Other
    'Child over 21': 2,
    'Additional spouse': 2,
    Aunt: 2,
    Uncle: 2,
    Grandchild: 2,
    Grandparent: 2,
    'Great Grandchild': 2,
    'Great Grandparent': 2,
    // Extended Other
    'First Cousin': 3,
    Nephew: 3,
    Niece: 3,
  },
  'sim-extended-single-life-funeral-flexi-v1': {
    // Immediate Family:
    Spouse: 4,
    'Child Under 21': 5,
    Parent: 0,
    Sibling: 1,
    // Other
    'Child over 21': 2,
    'Additional spouse': 2,
    Aunt: 2,
    Uncle: 2,
    Grandchild: 2,
    Grandparent: 2,
    'Great Grandchild': 2,
    'Great Grandparent': 2,
    // Extended Other
    'First Cousin': 3,
    Nephew: 3,
    Niece: 3,
  }
};

const getAffiliations = (extendedFamilyProductId, allowedRelationships) => {
  const affiliations = affiliationsByProduct[extendedFamilyProductId];
  const productAffiliations = affiliations || affiliationKeys;

  const filteredAffiliations = R.ifElse(
    () => R.isNil(allowedRelationships) || allowedRelationships.length === 0,
    R.keys,
    R.pipe(R.pick(allowedRelationships), R.keys)
  );

  return filteredAffiliations(productAffiliations);
};

const range = (min, maxOrStep, max) => {
  const nMax = max || maxOrStep;
  const step = max ? maxOrStep : 50;

  return R.range(min, nMax + 1).filter((n) => n % step === 0);
};

const halfOfFuneralCoverAmount = R.pipe(
  R.prop('currentFuneralCoverAmount'),
  R.multiply(0.5)
);

const maxOfFuneralCoverAmount = R.prop('currentFuneralCoverAmount');

const halfOfFuneralCoverAmountLessThan10k = R.ifElse(
  R.pipe(halfOfFuneralCoverAmount, R.lte(100)),
  halfOfFuneralCoverAmount,
  R.always(100)
);

const defaultMaxCover = R.ifElse(
  (options) => options.currentFuneralCoverAmount <= 500,
  R.prop('currentFuneralCoverAmount'),
  R.always(500)
);

const checkField = (field, checkFn) => R.pipe(R.prop(field), checkFn);
const isRelationship = (match) => checkField('relationship', R.equals(match));

const isChildUnder21 = isRelationship('Child Under 21');
const isSpouse = isRelationship('Spouse');

const isBetweenAge = R.curry((min, max, dob) => {
  const now = moment();
  const mDob = moment(dob, 'DDMMYYYY');
  const ageMillis = now.diff(mDob);
  const age = Math.floor(moment.duration(ageMillis).asYears());
  return age >= min && age < max;
});

const isChildBetween14and21 = R.allPass([
  isChildUnder21,
  checkField('dob', isBetweenAge(14, 21)),
]);
const isChildBetween6and14 = R.allPass([
  isChildUnder21,
  checkField('dob', isBetweenAge(6, 14)),
]);
const isChildBetween0and6 = R.allPass([
  isChildUnder21,
  checkField('dob', isBetweenAge(0, 6)),
]);

const getAffiliateMaxCover = R.cond([
  [isSpouse, maxOfFuneralCoverAmount],
  [isChildBetween14and21, maxOfFuneralCoverAmount],
  [isChildBetween6and14, halfOfFuneralCoverAmount],
  [isChildBetween0and6, halfOfFuneralCoverAmountLessThan10k],
  [R.T, defaultMaxCover],
]);

const getAffiliatePriceList = ({
  currentFuneralCoverAmount,
  relationship,
  dob,
}) => {
  const affiliateMaxCover = getAffiliateMaxCover({
    relationship,
    currentFuneralCoverAmount,
    dob,
  });
  return range(100, affiliateMaxCover);
};

const getAffiliationsMap = (extendedFamilyProductId, allowedRelationships) =>
  R.ifElse(
    () => R.isNil(allowedRelationships) || allowedRelationships.length === 0,
    R.identity,
    R.pick(allowedRelationships)
  )(affiliationsByProduct[extendedFamilyProductId] || affiliationKeys);

const insuredRow =
  (editInsured, removeInsured) =>
  ({ id, premium, insuredAmount, surname, name, age, relationship }) =>
    (
      <React.Fragment>
        <tr key={id}>
          <TD>
            {`${name.answer} ${surname.answer}`} - {relationship.answer}
            <br /> <i>{age.answer} years old</i>
          </TD>
          <TD>{`${toCurrencyNoDecimal(insuredAmount.answer * 100)}`}</TD>
          <TD>{` ${toCurrency(premium.answer)}pm`}</TD>
          <TD>
            <Buttons alignment="right">
              <SmallPrimaryButton onClick={() => editInsured(id)}>
                Edit
              </SmallPrimaryButton>
              <SmallDangerButton onClick={() => removeInsured(id)}>
                Delete
              </SmallDangerButton>
            </Buttons>
          </TD>
        </tr>
      </React.Fragment>
    );
const initialState = {
  formCollapsed: false,
};

const extendedFamilyControlId = 'extended-family-funeral-customization';

class ExtendedFamilyFuneralSelector extends PureComponent {
  constructor() {
    super();
    this.toggleCollapseForm = this.toggleCollapseForm.bind(this);
    this.editInsured = this.editInsured.bind(this);
    this.deleteInsured = this.deleteInsured.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.state = initialState;
    this.onAddEditUpdateInsuredClicked =
      this.onAddEditUpdateInsuredClicked.bind(this);
    this.toggleShowContent = this.toggleShowContent.bind(this);
    this.state = { showContent: false };
  }

  componentWillMount() {
    const shouldCollapseForm = R.or(
      R.isNil(this.props.insureds),
      R.not(R.isEmpty(this.props.insureds))
    );
    this.setState({ formCollapsed: shouldCollapseForm });
  }

  toggleCollapseForm() {
    this.setState({ formCollapsed: !this.state.formCollapsed });
  }

  editInsured(insuredId) {
    if (this.state.formCollapsed) {
      this.setState({ formCollapsed: false });
    }
    this.props.editInsured(insuredId, this.props.extendedFamilyProductId);
  }

  deleteInsured(insuredId) {
    this.props.deleteInsured(insuredId, this.props.extendedFamilyProductId);
  }

  onAddEditUpdateInsuredClicked(event) {
    const {
      addInsured,
      extendedFamilyProductId,
      currentExtendedFamilyInsured,
      updateInsured,
    } = this.props;

    event.preventDefault();
    const dispatchFn = currentExtendedFamilyInsured.editing
      ? updateInsured
      : addInsured;
    dispatchFn(extendedFamilyProductId);
    this.scrollToTop();
    this.toggleCollapseForm();
  }

  scrollToTop() {
    const elem = document.getElementById(extendedFamilyControlId);
    if (elem) {
      setTimeout(
        () =>
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          }),
        500
      );
    }
  }

  toggleShowContent() {
    const { showContent } = this.state;
    this.setState({ showContent: !showContent });
  }

  render() {
    const {
      toggleExtendedFamily,
      withExtendedFamily,
      currentExtendedFamilyInsured,
      insureds,
      extendedFamilyProductId,
      refreshCurrentInsured,
      insuredRefreshed,
      maximumFuneralCoverAmount,
      effProduct,
      allowedRelationships,
      effProductTooltip,
      effTooltipLink,
      effTooltipDetails,
      effTooltipExtraDetail,
      packageAllowsEffWithoutFuneral,
      currentFuneralCoverAmount,
    } = this.props;

    const {
      name,
      surname,
      cellphoneNumber,
      affiliation,
      gender,
      prices,
      selected,
      insuredAmount,
      premium,
      dateOfBirth,
      editing,
      affiliationKey,
      premiumWithRop,
    } = currentExtendedFamilyInsured;
    const coverIndex = currentExtendedFamilyInsured.cover || undefined;
    const addEditMemberButtonText = R.cond([
      [R.always(editing), R.always('Update member')],
      [R.T, R.always('Add member')],
    ])();
    const setCurrentInsuredValue = (value, answer) => {
      this.props.setCurrentInsuredValue(
        { value, answer },
        extendedFamilyProductId
      );
    };
    const answerDateOfBirth = (answer) =>
      setCurrentInsuredValue('dateOfBirth', answer);
    const answerFirstName = (answer) => setCurrentInsuredValue('name', answer);
    const answerSurname = (answer) => setCurrentInsuredValue('surname', answer);
    const affiliationsMap = getAffiliationsMap(
      extendedFamilyProductId,
      allowedRelationships
    );
    const answerAffiliation = (answer) => {
      this.props.setCurrentInsuredValues(
        {
          affiliationKey: { answer: affiliationsMap[answer.value] },
          affiliation: { answer: answer.value },
          relationship: { answer: answer.value },
        },
        extendedFamilyProductId
      );
    };
    const answerCellphone = (answer) =>
      setCurrentInsuredValue('cellphoneNumber', answer);
    const answerGender = (answer) => setCurrentInsuredValue('gender', answer);

    const setCover = debounce((answer) => {
      if (answer > 0) {
        const coverAndPremium = R.pipe(
          R.prop(extendedFamilyProductId),
          R.keys,
          R.prop(answer - 1),
          (cover) => ({
            cover,
            premium: R.path(
              [extendedFamilyProductId, cover, 'premium'],
              prices
            ),
            premiumWithRop: R.path(
              [extendedFamilyProductId, cover, 'premiumWithRop'],
              prices
            ),
          })
        )(prices);
        setCurrentInsuredValue('cover', answer);
        setCurrentInsuredValue(
          'insuredAmount',
          Number.parseInt(coverAndPremium.cover, 10)
        );
        setCurrentInsuredValue(
          'premium',
          Number.parseInt(coverAndPremium.premium, 10)
        );
        setCurrentInsuredValue(
          'premiumWithRop',
          Number.parseInt(coverAndPremium.premiumWithRop, 10)
        );
      }
    }, 200);
    const insuredHasPricing = prices !== null && prices !== undefined;
    const hasCover = !(
      R.isNil(R.prop('answer', insuredAmount)) ||
      R.isNil(R.prop('answer', premium)) ||
      R.isNil(R.prop('answer', premiumWithRop))
    );
    if (refreshCurrentInsured) {
      insuredRefreshed();
    }
    const insuredIsValid = R.reduce(
      (valid, { isValid = false }) => valid && isValid,
      true,
      [name, surname, cellphoneNumber, gender, dateOfBirth, affiliationKey]
    );
    const showMembers =
      insureds !== null && insureds !== undefined && insureds.length > 0;
    const priceMap = insuredHasPricing ? prices[extendedFamilyProductId] : {};
    const pricingKeys = R.keys(priceMap).filter(
      (k) => Number.parseInt(k, 10) <= maximumFuneralCoverAmount
    );
    const priceListOptions = {
      currentFuneralCoverAmount: this.props.currentFuneralCoverAmount,
      relationship: affiliation.answer,
      dob: dateOfBirth.answer,
    };

    const defaultProductPricing = getAffiliatePriceList(
      priceListOptions
    ).reduce((pricing, step) => R.assoc(step, 0, pricing), {});

    const productPricing = !R.isEmpty(priceMap)
      ? R.mergeAll(pricingKeys.map((k) => R.assoc(k, priceMap[k].premium, {})))
      : defaultProductPricing;

    const productTooltip =
      premium && selected && premium.answer && selected.answer === true
        ? `Premium: ${toCurrencyNoDecimal(premium.answer)}`
        : R.empty('');
    const insuredAmountNotSelected =
      selected !== undefined && selected.answer === false;

    const noPriceAvailable = R.isEmpty(productPricing);
    return (
      <>
        <ShadowedContainer>
          <div id={extendedFamilyControlId}>
            <div>
              <AcceptCheckboxQuestion
                onChange={() => toggleExtendedFamily(!withExtendedFamily)}
                checked={withExtendedFamily}
                text={effProduct.get('actionText')}
                labelIsBold
              />
              <div
                className={`${
                  showMembers || withExtendedFamily || this.state.formCollapsed
                    ? 'space-below'
                    : ''
                }`}
              >
                <Tooltip className="text-left small">
                  {effProductTooltip}

                  {effTooltipLink && (
                    <div className="toggle mt-8">
                      <Input
                        type="checkbox"
                        value="selected"
                        id="title"
                        className="toggle-input hidden"
                      />
                      <p className="mb-0">
                        <span
                          className="text-left small"
                          htmlFor="title"
                          onClick={this.toggleShowContent}
                          dangerouslySetInnerHTML={{ __html: effTooltipLink }} // eslint-disable-line
                        />
                      </p>
                      {this.state.showContent && (
                        <ul className="indent">
                          {effTooltipDetails.map((d, i) => {
                            if (R.head(d) === '-') {
                              return (
                                <LI className="text-left small" key={i}>
                                  {R.tail(d)}
                                </LI>
                              );
                            }
                            return (
                              <LI className="text-left small" key={i}>
                                {d}{' '}
                              </LI>
                            );
                          })}
                        </ul>
                      )}
                      {this.state.showContent && (
                        <div>{effTooltipExtraDetail}</div>
                      )}
                    </div>
                  )}
                </Tooltip>
                {withExtendedFamily &&
                  currentFuneralCoverAmount === 0 &&
                  packageAllowsEffWithoutFuneral && (
                    <Tooltip className="text-left small">
                      If you don’t already have funeral cover from another
                      insurer or via your employer, you need to take funeral
                      cover above to qualify for this benefit.
                    </Tooltip>
                  )}
              </div>
            </div>

            {withExtendedFamily && (
              <React.Fragment>
                {showMembers && (
                  <Table>
                    <thead>
                      <tr>
                        <TH>Name</TH>
                        <TH>Cover</TH>
                        <TH>Premium</TH>
                        <TH></TH>
                      </tr>
                    </thead>
                    <tbody>
                      {insureds.map(
                        insuredRow(this.editInsured, this.deleteInsured)
                      )}
                    </tbody>
                  </Table>
                )}
                {!refreshCurrentInsured && !this.state.formCollapsed && (
                  <Form>
                    {inputQuestionField(
                      'First Name',
                      '',
                      answerFirstName,
                      'extended-family-insured-first-name',
                      name
                    )}
                    {inputQuestionField(
                      'Surname',
                      '',
                      answerSurname,
                      'extended-family-insured-surname',
                      surname
                    )}
                    <QuestionContainer>
                      <DateQuestion
                        title="Date of Birth"
                        answerQuestion={answerDateOfBirth}
                        initialValue={dateOfBirth}
                        {...dateOfBirth}
                      />
                    </QuestionContainer>
                    {inputQuestionField(
                      'Cellphone Number',
                      'Optional',
                      answerCellphone,
                      'extended-family-insured-cellphone',
                      cellphoneNumber
                    )}
                    <QuestionContainer>
                      <InputSelect
                        id="extended_family_member_relationship"
                        label="Relationship"
                        options={getAffiliations(
                          extendedFamilyProductId,
                          allowedRelationships
                        ).map((a) => ({
                          value: a,
                          label: a,
                        }))}
                        onChange={answerAffiliation}
                        placeholder={affiliation.answer}
                      />
                    </QuestionContainer>
                    <QuestionContainer>
                      <RadioSelectQuestion
                        id="extended_family_member_gender"
                        title="Gender"
                        possibleAnswers={['Male', 'Female']}
                        {...gender}
                        answerQuestion={answerGender}
                        noMargin
                      />
                    </QuestionContainer>
                  </Form>
                )}
                {!refreshCurrentInsured &&
                  insuredHasPricing &&
                  insuredIsValid &&
                  !this.state.formCollapsed && (
                    <ProductSlider
                      title={effProduct.get('title')}
                      notSelectedText="cover not selected"
                      priceSuffix="cover"
                      insuredAmount={`${insuredAmount.answer}`}
                      onSlide={setCover}
                      value={coverIndex.answer}
                      productPrices={productPricing}
                      productId={extendedFamilyProductId}
                      onToggle={(s) => setCurrentInsuredValue('selected', s)}
                      selected={selected && selected.answer}
                      tooltip={productTooltip}
                    />
                  )}
                {!this.state.formCollapsed &&
                  noPriceAvailable &&
                  !packageAllowsEffWithoutFuneral && (
                    <Errors
                      errors={[
                        "You will need to increase the cover on the main life's funeral benefit before you can add this family member",
                      ]}
                    />
                  )}
                {!this.state.formCollapsed && (
                  <PageButtons
                    disabled={
                      !insuredIsValid || !hasCover || insuredAmountNotSelected
                    }
                    onClick={this.onAddEditUpdateInsuredClicked}
                    label={addEditMemberButtonText}
                  />
                )}

                {this.state.formCollapsed && (
                  <PageButtons
                    onClick={this.toggleCollapseForm}
                    label="Add another"
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </ShadowedContainer>
      </>
    );
  }
}

export default ExtendedFamilyFuneralSelector;

ExtendedFamilyFuneralSelector.propTypes = {
  withExtendedFamily: PropTypes.bool.isRequired,
  toggleExtendedFamily: PropTypes.func.isRequired,
  setCurrentInsuredValue: PropTypes.func.isRequired,
  currentExtendedFamilyInsured: PropTypes.object.isRequired,
  addInsured: PropTypes.func.isRequired,
  maximumFuneralCoverAmount: PropTypes.number.isRequired,
};
